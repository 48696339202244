import dayjs from 'dayjs'
import Timezone from 'dayjs/plugin/timezone'
import UTC from 'dayjs/plugin/utc'

dayjs.extend(UTC)
dayjs.extend(Timezone)

export const fromUnix = val => {
  if (!val) return val

  const formatter = v => v * 1000

  if (Array.isArray(val)) {
    return val.map(formatter)
  }

  return formatter(val)
}

export const toUnix = (val, timezone = dayjs.tz.guess()) => {
  if (!val) return val

  const formatter = v => dayjs(v).tz(timezone).unix()

  if (Array.isArray(val)) {
    return val.map(formatter)
  }

  return formatter(val)
}

export const getDayjsCompatibleLocale = locale => {
  const localesFallbackMap = {
    en: ['en_GB'],
    'pt-br': ['pt_BR'],
    'zh-cn': ['zh_CN'],
    'zh-tw': ['zh_TW'],
    'fr-ca': ['fr_CA'],
    nb: ['no']
  }
  const fallbackLocale = Object.keys(localesFallbackMap).find(key => localesFallbackMap[key].includes(locale))

  return fallbackLocale || locale
}
